$(function() {

	// mask phone
	$('.phone').mask("+7 (999) 999-99-99", {placeholder: "_"});

	// nanoscroller
	$('nano').nanoScroller({alwaysVisible: true});

	// header modificate
	$(window).scroll(function () {
		if (this.pageYOffset > 0) {
			$('header').addClass('active');
		} else {
			$('header').removeClass('active');
		}
	}).trigger('scroll');

	// select styled
	if ($('select').length) {
		$('select').styler();
	};

	$('.modal').on('shown.bs.modal', function (e) {
		$('select').styler('reinitialize');
	});

	// mobile menu
	$('.header-menu, .hside-btnmenu, .gm-head-close').on('click', function () {
		$(this).toggleClass('active');
		$('.globalmenu').toggleClass('active');
		$('html').toggleClass('gmactive');
		return false;
	});

	// slider actions
	if ( $('.actions-sl').length ) {
		var allslides_actions = $('.actions-sl-el').length;

		var SlActions = new Swiper('.actions-sl', {
			slidesPerView: 1,
			slidesPerGroup: 1,
			spaceBetween: 20,
			navigation: {
				nextEl: '#actions-sl-next',
				prevEl: '#actions-sl-prev',
			},
			breakpoints: {
				768: {
					slidesPerView: 2,
					slidesPerGroup: 2,
				},
				992: {
					slidesPerView: 2,
					slidesPerGroup: 2,
					spaceBetween: 70,
				},
				1200: {
					slidesPerView: 3,
					slidesPerGroup: 3,
					spaceBetween: 70,
				},
				1517: {
					slidesPerView: 3,
					slidesPerGroup: 3,
					spaceBetween: 140,
				}
			},
			on: {
				init: function () {
					$('#actions-numbers').html( '<span>' + (this.activeIndex + 1) + '</span> / ' + allslides_actions );
				},
				slideChange: function () {
					$('#actions-numbers').html( '<span>' + (this.activeIndex + 1) + '</span> / ' + allslides_actions );
				}
			}
		});
	}

	// slider actions
	if ( $('.elseactions-sl').length ) {
		var allslides_elseactions = $('.elseactions-sl-el').length;

		var SlActionsElse = new Swiper('.elseactions-sl', {
			slidesPerView: 1,
			slidesPerGroup: 1,
			spaceBetween: 20,
			navigation: {
				nextEl: '#elseactions-sl-next',
				prevEl: '#elseactions-sl-prev',
			},
			breakpoints: {
				768: {
					slidesPerView: 2,
					slidesPerGroup: 2,
				},
				992: {
					slidesPerView: 2,
					slidesPerGroup: 2,
					spaceBetween: 30,
				},
				1517: {
					slidesPerView: 3,
					slidesPerGroup: 3,
					spaceBetween: 30,
				}
			},
			on: {
				init: function () {
					$('#elseactions-numbers').html( '<span>' + (this.activeIndex + 1) + '</span> / ' + allslides_elseactions );
				},
				slideChange: function () {
					$('#elseactions-numbers').html( '<span>' + (this.activeIndex + 1) + '</span> / ' + allslides_elseactions );
				}
			}
		});
	}

	// faq
	$('.faq-title').on('click', function () {
		$(this).next('.faq-slide').slideToggle('fast');
		$(this).parent('.faq-el').toggleClass('active');
		return false;
	});

	// anchor scroll
	$('.linkto').on('click', function(e){
		var anchor = $(this);
		if ( $('header').is(':visible') ) {
			$('html, body').stop().animate({
				scrollTop: $(anchor.attr('href')).offset().top - $('header').outerHeight(true)
			}, 800);
		} else {
			$('html, body').stop().animate({
				scrollTop: $(anchor.attr('href')).offset().top
			}, 800);
		}

		e.preventDefault();
	});
	// menu anchor scroll
	$('.gm-nav a').on('click', function(e){

		$('.hside-btnmenu, .header-menu').toggleClass('active');
		$('.globalmenu').toggleClass('active');
		$('html').toggleClass('gmactive');

		var anchor = $(this);
		$('html, body').stop().animate({
			scrollTop: $(anchor.attr('href')).offset().top - $('header').outerHeight(true)
		}, 800);
		e.preventDefault();
	});

	// training
	if ( $('.training-table').length ) {
		var trainingEl = document.querySelector('.training-table');
		var mixerTraining = mixitup(trainingEl, {
			selectors: {
				target: '.training-table-el',
				control: '.control'
			},
			animation: {
				enable: false,
			}
		});
		mixerTraining.filter('.children');
	}

	// halls slider
	if ($('.halls-sl').length > 0) {
		var allslides_halls = $('.halls-sl-el').length;

		var l = function() {
			var n = 0;

			if ( $(window).width() > 992 ) {
				return n = $('.ptitle').offset().left - 61;
			} else {
				return n = $('.ptitle').offset().left + 15;
			}
			n = parseInt(n);
			n;
		};

		l();

		var SlHalls = new Swiper(".halls-sl", {
			init: !1,
			slidesPerView: "auto",
			freeMode: !0,
			speed: 1e3,
			slidesOffsetBefore: l(),
			slidesOffsetAfter: l(),
			spaceBetween: 15,
			slideToClickedSlide: !0,
			navigation: {
				nextEl: '#halls-sl-next',
				prevEl: '#halls-sl-prev'
			},
			breakpoints: {
				992: {
					spaceBetween: 30,
				}
			},
			on: {
				init: function () {
					$('#halls-numbers').html( '<span>' + (this.activeIndex + 1) + '</span> / ' + allslides_halls );
				},
				slideChange: function () {
					$('#halls-numbers').html( '<span>' + (this.activeIndex + 1) + '</span> / ' + allslides_halls );
				}
			}
		});

		SlHalls.init();

		$(window).resize(function() {
			SlHalls.params.slidesOffsetBefore = l();
			SlHalls.update(!0);
			0 === SlHalls.activeIndex && SlHalls.slideTo(0)
		});

		$(window).on("orientationchange", function() {
			SlHalls.params.slidesOffsetBefore = l();
			SlHalls.update(!0);
			0 === SlHalls.activeIndex && SlHalls.slideTo(0)
		});
	};

	// schedule
	$('.schedule-title').on('click', function () {
		$(this).toggleClass('active');
	});

	// address
	$('.header-club-link').on('click', function () {
		$(this).toggleClass('active');
		$(this).next('.header-club-popup').slideToggle('fast');
		return false;
	});

	$(document).mouseup(function (e){
		var div = $(".header-club-body");
		if (!div.is(e.target)
			&& div.has(e.target).length === 0) {
			div.find('.header-club-popup').slideUp('fast');
			div.find('.header-club-link').removeClass('active')
		}
	});

	// slider reviews
	if ( $('.reviews-sl').length ) {
		var allslides_reviews = $('.reviews-sl-el').length;

		var SlReviews = new Swiper('.reviews-sl', {
			slidesPerView: 1,
			slidesPerGroup: 1,
			spaceBetween: 20,
			autoHeight: true,
			navigation: {
				nextEl: '#reviews-sl-next',
				prevEl: '#reviews-sl-prev',
			},
			breakpoints: {
				992: {
					slidesPerView: 2,
					slidesPerGroup: 2,
					spaceBetween: 30,
				}
			},
			on: {
				init: function () {
					$('#reviews-numbers').html( '<span>' + (this.activeIndex + 1) + '</span> / ' + allslides_reviews );
					$('.nano').nanoScroller({alwaysVisible: true});
				},
				slideChange: function () {
					$('#reviews-numbers').html( '<span>' + (this.activeIndex + 1) + '</span> / ' + allslides_reviews );
				}
			}
		});
	}

	$('.inp-input').each(function() {
		if ( $(this).val() !== '' ) {
			$(this).parents('.inp').addClass('active');
		};
	});

	$('.inp-input').focus(function() {
		$(this).parents('.inp').addClass('active');
	});

	$('.inp-input').blur(function() {
		if ( $(this).val() !== '' ) {
			$(this).parents('.inp').addClass('active');
		} else {
			$(this).parents('.inp').removeClass('active');
		};
	});

	// slider staff
	$('.staff-link').on('click', function () {
		$(this).parents('.staff-el').addClass('active').siblings('div').removeClass('active');
		$('#mstaff').modal('show');
		return false;
	});
	$('#mstaff').on('shown.bs.modal', function () {
		var allslides = $('.mstaff-sl-el').length,
			indexactive = 1;

		var SlStaff = new Swiper('.mstaff-sl', {
			loop: false,
			slidesPerView: 1,
			spaceBetween: 0,
			navigation: {
				nextEl: '#mstaff-sl-next',
				prevEl: '#mstaff-sl-prev',
			},
			on: {
				init: function () {
					var indexactive = $('.staff-el.active').index() + 1;

					$('.nano').nanoScroller({alwaysVisible: true});
					this.slideTo( $('.staff-el.active').index() );

					$('.mstaff .sl-nav-numbers').html( '<span>' + indexactive + '</span> / ' + allslides );
				},
				slideChange: function () {
					var indexactive = this.activeIndex + 1;

					$('.mstaff .sl-nav-numbers').html( '<span>' + indexactive + '</span> / ' + allslides );
				}
			}
		});
	});

	//	instagram
	$('.fixinstagram-link').on('click', function () {
		$(this).toggleClass('active');
		$(this).next('.fixinstagram-slide').slideToggle('fast');
		return false;
	});
	$('.fixinstagram-link.active').each(function () {
		$(this).next('.fixinstagram-slide').slideUp(0);
	});

});